import { useQuery } from '@tanstack/react-query';
import statsService from '@/services/http/StatsService';

import { LuUsers } from 'react-icons/lu';

import CardStatsVertical from '@core/components/card-statistics/card-stats-vertical';

export default function CustomerStats() {
  const { data, isLoading } = useQuery({
    queryFn: () => statsService.getCustomers(),
    queryKey: ['statsService.getCustomers'],
  });

  return (
    <CardStatsVertical
      title='Customers'
      icon={LuUsers}
      isLoading={isLoading}
      url='/sales/customers'
      stats={data ? data.count.toString() : ''}
      trendPercentage={data ? data.trendPercentage : 0}
    />
  );
}
