import HttpService from '@/services/HttpService';

interface IQuoteStats {
  count: number;
  trendPercentage: number;
}

interface IOrderStats {
  count: number;
  trendPercentage: number;
}

interface IProjectStats {
  count: number;
  trendPercentage: number;
}

interface ICustomerStats {
  count: number;
  trendPercentage: number;
}

class StatsService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Stats';

  async getQuotes() {
    const response = await this.getBase<IQuoteStats>(`${this.url}/Quotes`);

    return response.data;
  }

  async getOrders() {
    const response = await this.getBase<IOrderStats>(`${this.url}/Orders`);

    return response.data;
  }

  async getProjects() {
    const response = await this.getBase<IProjectStats>(`${this.url}/Projects`);

    return response.data;
  }

  async getCustomers() {
    const response = await this.getBase<ICustomerStats>(`${this.url}/Customers`);

    return response.data;
  }
}

export default new StatsService();
