import { Fragment } from 'react';

import Head from 'next/head';

import PageHeader from '@/layouts/page-header';
import Dashboard from '@/views/dashboard/Dashboard';

const Index = () => {
  const title = 'Dashboard';

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
      </Head>
      <PageHeader title={title} />
      <Dashboard />
    </Fragment>
  );
};

export default Index;
