import { useQuery } from '@tanstack/react-query';
import statsService from '@/services/http/StatsService';

import { AiOutlineProject } from 'react-icons/ai';

import CardStatsVertical from '@core/components/card-statistics/card-stats-vertical';

export default function ProjectStats() {
  const { data, isLoading } = useQuery({
    queryFn: () => statsService.getProjects(),
    queryKey: ['statsService.getProjects'],
  });

  return (
    <CardStatsVertical
      title='Projects'
      icon={AiOutlineProject}
      isLoading={isLoading}
      url='/sales/projects'
      stats={data ? data.count.toString() : ''}
      trendPercentage={data ? data.trendPercentage : 0}
    />
  );
}
