import { useQuery } from '@tanstack/react-query';
import statsService from '@/services/http/StatsService';

import { TbFileDollar } from 'react-icons/tb';

import CardStatsVertical from '@core/components/card-statistics/card-stats-vertical';

export default function OrderStats() {
  const { data, isLoading } = useQuery({
    queryFn: () => statsService.getOrders(),
    queryKey: ['statsService.getOrders'],
  });

  return (
    <CardStatsVertical
      title='Orders'
      icon={TbFileDollar}
      isLoading={isLoading}
      url='/sales/orders'
      stats={data ? data.count.toString() : ''}
      trendPercentage={data ? data.trendPercentage : 0}
    />
  );
}
