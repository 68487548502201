import Grid from '@mui/material/Unstable_Grid2';

import QuoteStats from './QuoteStats';
import OrderStats from './OrderStats';
import ProjectStats from './ProjectStats';
import CustomerStats from './CustomerStats';

import usePermissions from '@/hooks/usePermissions';
import permissions from '@/configs/permissions';

export default function Dashboard() {
  const { has } = usePermissions();

  return (
    <Grid container spacing={3}>
      {has(permissions.sales.quote) && (
        <Grid xs={6} sm={4} md={3} lg={2} xl={1.5}>
          <QuoteStats />
        </Grid>
      )}
      {has(permissions.sales.order) && (
        <Grid xs={6} sm={4} md={3} lg={2} xl={1.5}>
          <OrderStats />
        </Grid>
      )}
      {has(permissions.sales.project) && (
        <Grid xs={6} sm={4} md={3} lg={2} xl={1.5}>
          <ProjectStats />
        </Grid>
      )}
      {has(permissions.sales.customer) && (
        <Grid xs={6} sm={4} md={3} lg={2} xl={1.5}>
          <CustomerStats />
        </Grid>
      )}
    </Grid>
  );
}
