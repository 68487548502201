// ** MUI
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

interface IPageHeaderProps {
  title: string;
  subtitle?: string;
}

export default function PageHeader(props: IPageHeaderProps) {
  const { title, subtitle } = props;

  const theme = useTheme();

  return (
    <Paper sx={{ paddingLeft: 2.5, paddingTop: 1, paddingBottom: 1, marginBottom: 2 }}>
      <Typography sx={{ color: theme.palette.text.primary }} variant='h6'>
        {title}
      </Typography>
      {subtitle && <Typography variant='body2'>{subtitle}</Typography>}
    </Paper>
  );
}
