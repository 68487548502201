// ** This file has been modified - YC

// ** React Import
import { Fragment } from 'react';

// ** Next Import
import Link from 'next/link';

// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

// ** Types Imports
import { CardStatsVerticalProps } from '@core/components/card-statistics/types';

// ** Icons Imports
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';

// ** Custom Components Imports
import CustomAvatar from '@core/components/mui/avatar';
import OptionsMenu from '@core/components/option-menu';

const CardStatsVertical = (props: CardStatsVerticalProps) => {
  // ** Props
  const {
    title,
    stats,
    icon: Icon,
    iconColor = 'primary',
    trendPercentage,
    optionsMenuProps,
    isLoading = false,
    url,
  } = props;

  return (
    <Card>
      <CardContent sx={{ p: (theme) => `${theme.spacing(5, 5, 4)} !important` }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <CustomAvatar skin='light' variant='rounded' color={iconColor} sx={{ width: 42, height: 42, mb: 4 }}>
            <Icon />
          </CustomAvatar>
          {optionsMenuProps && <OptionsMenu {...optionsMenuProps} />}
        </Box>
        {url ? (
          <Link href={url} style={{ textDecoration: 'none' }}>
            <Typography sx={{ mb: 0.5, fontWeight: 600, color: 'text.secondary' }}>{title}</Typography>
          </Link>
        ) : (
          <Typography sx={{ mb: 0.5, fontWeight: 600, color: 'text.secondary' }}>{title}</Typography>
        )}
        <Typography variant='h5'>{isLoading ? <Skeleton variant='text' width={50} /> : stats}</Typography>
        {trendPercentage !== undefined && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& svg': { mr: 0.5, mt: 1.5, color: `${trendPercentage > 0 ? 'success' : 'error'}.main` },
            }}
          >
            {isLoading ? (
              <Skeleton variant='text' width={70} />
            ) : (
              <Fragment>
                {trendPercentage > 0 ? <BiUpArrowAlt fontSize={16} /> : <BiDownArrowAlt fontSize={16} />}
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 500, marginTop: 1.5, color: `${trendPercentage > 0 ? 'success' : 'error'}.main` }}
                >
                  {`${trendPercentage}%`}
                </Typography>
              </Fragment>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CardStatsVertical;
